var render = function () {
  var _vm$reservationsData3, _vm$reservationsData4, _vm$reservationsData5, _vm$reservationsData6, _vm$reservationsData7, _vm$reservationsData8, _vm$reservationsData9, _vm$reservationsData10, _vm$reservationsData11, _vm$reservationsData$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column justify-content-between"
  }, [_c('b-card', {
    staticClass: "border-info-2 shadow-card mb-50",
    attrs: {
      "body-class": "py-75 pb-md-1 px-75 px-md-2"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-md-row gap-1",
    class: _vm.isMobileView ? 'flex-column' : ''
  }, [_c('div', {
    class: "".concat(_vm.isMobileView ? '' : 'flex-1')
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-25 flex-wrap"
  }, [_c('div', {
    staticClass: "d-flex-center border-airline px-1 py-75 bg-light-info rounded-8px my-25",
    staticStyle: {
      "width": "fit-content"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder d-flex-center justify-content-start divider-after"
  }, [_c('b', {
    staticClass: "text-airline font-large-1 text-nowrap",
    class: "".concat(_vm.isMobileView ? 'font-medium-3' : 'font-large-1')
  }, [_vm._v(" " + _vm._s(_vm.reservationsData.bookingCode || _vm.reservationsData.pnr) + " ")]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window.top",
      value: _vm.$t('copy'),
      expression: "$t('copy')",
      modifiers: {
        "hover": true,
        "window": true,
        "top": true
      }
    }],
    staticClass: "p-50 rounded-circle ml-25",
    attrs: {
      "variant": "flat-primary",
      "disabled": _vm.disabledCopy
    },
    on: {
      "click": function click($event) {
        var _vm$reservationsData, _vm$reservationsData2;
        return _vm.copyPnr(((_vm$reservationsData = _vm.reservationsData) === null || _vm$reservationsData === void 0 ? void 0 : _vm$reservationsData.bookingCode) || ((_vm$reservationsData2 = _vm.reservationsData) === null || _vm$reservationsData2 === void 0 ? void 0 : _vm$reservationsData2.pnr));
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "copy",
      "size": "18",
      "color": "#2FA4E5"
    }
  })], 1)], 1), _c('IAmLogoAirline', {
    attrs: {
      "right": "",
      "airline": _vm.reservationsData.airline || _vm.reservationsData.airlines.split(' | ')[0] || _vm.reservationsData.source,
      "size": "sm"
    }
  })], 1), (_vm$reservationsData3 = _vm.reservationsData) !== null && _vm$reservationsData3 !== void 0 && _vm$reservationsData3.roundtripBooking || !_vm.isEmpty((_vm$reservationsData4 = _vm.reservationsData) === null || _vm$reservationsData4 === void 0 ? void 0 : _vm$reservationsData4.multiCityBookings) || !_vm.isEmpty((_vm$reservationsData5 = _vm.reservationsData) === null || _vm$reservationsData5 === void 0 ? void 0 : _vm$reservationsData5.crossReferenceDetails) ? _c('div', [_c('b-img', {
    staticClass: "bg-light-info rounded-circle p-50 my-25",
    class: _vm.isMobileView ? "mx-25" : "mx-1",
    attrs: {
      "src": require("@icons/link_alt.svg"),
      "width": "30"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-row gap-1 flex-wrap"
  }, [(_vm$reservationsData6 = _vm.reservationsData) !== null && _vm$reservationsData6 !== void 0 && _vm$reservationsData6.roundtripBooking ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "p-25 p-md-50 d-flex align-items-end border-airline my-25",
    attrs: {
      "variant": "flat-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.openLinkedBookingHandle(_vm.reservationsData.roundtripBooking.id);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center mr-50"
  }, [_c('IAmLogoAirline', {
    attrs: {
      "right": "",
      "airline": _vm.reservationsData.roundtripBooking.airlines ? _vm.reservationsData.roundtripBooking.airlines.split(' | ')[0] : _vm.reservationsData.roundtripBooking.source,
      "size": "sm"
    }
  }), _c('i', {
    staticClass: "text-nowrap",
    class: _vm.isMobileView ? 'font-small-1 ' : 'font-small-3 '
  }, [_vm._v(" (" + _vm._s(_vm.$t('reservation.roundtripTicket')) + ") ")])], 1), _c('div', {
    staticClass: "text-airline",
    class: _vm.isMobileView ? "font-small-4" : "font-medium-4"
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.reservationsData.roundtripBooking.bookingCode) + " ")]), !_vm.isEmpty(_vm.reservationsData.roundtripBooking.itineraries) ? _c('div', {
    staticClass: "mt-75",
    class: _vm.isMobileView ? 'font-small-3 d-flex px-25' : 'font-medium-1'
  }, [_c('span', {
    class: _vm.isMobileView ? "font-small-3" : "font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.reservationsData.roundtripBooking.itineraries[0][0].departure.iataCode) + " ")]), !_vm.isMobileView ? _c('b-img', {
    class: _vm.isMobileView ? "mx-25" : "mx-1",
    attrs: {
      "src": require("@icons/swap-horizontal.svg"),
      "width": _vm.isMobileView ? "13" : "16"
    }
  }) : _c('span', [_vm._v(" - ")]), _c('span', {
    class: _vm.isMobileView ? "font-small-2" : "font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.reservationsData.roundtripBooking.itineraries[0][_vm.reservationsData.roundtripBooking.itineraries[0].length - 1].arrival.iataCode) + " ")])], 1) : _c('div', {
    staticClass: "font-medium-1 mt-75"
  }, [_vm.reservationsData ? _c('b-badge', {
    staticClass: "badge-glow round",
    attrs: {
      "variant": "light-".concat(_vm.resolveBookingStatusVariant(_vm.reservationsData.roundtripBooking.status || _vm.reservationsData.roundtripBooking.bookingStatus))
    }
  }, [_c('small', {
    class: "font-weight-bolder text-".concat(_vm.resolveBookingStatusVariant(_vm.reservationsData.roundtripBooking.status || _vm.reservationsData.roundtripBooking.bookingStatus))
  }, [_vm._v(" " + _vm._s(_vm.reservationsData.roundtripBooking.status || _vm.reservationsData.roundtripBooking.bookingStatus) + " ")])]) : _vm._e()], 1)])]) : _vm._e(), !_vm.isEmpty((_vm$reservationsData7 = _vm.reservationsData) === null || _vm$reservationsData7 === void 0 ? void 0 : _vm$reservationsData7.multiCityBookings) ? _c('div', {
    staticClass: "d-flex flex-row gap-1 flex-wrap"
  }, _vm._l(_vm.reservationsData.multiCityBookings, function (linkedReservationsData) {
    return _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      key: linkedReservationsData.id,
      staticClass: "p-25 p-md-50 d-flex align-items-center border-airline my-25",
      attrs: {
        "variant": "flat-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openLinkedBookingHandle(linkedReservationsData.id);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column align-items-center mr-50"
    }, [_c('IAmLogoAirline', {
      attrs: {
        "right": "",
        "airline": _vm.resolveAirlineImg(linkedReservationsData),
        "size": "sm"
      }
    }), _c('i', {
      staticClass: "text-nowrap",
      class: _vm.isMobileView ? 'font-small-1 ' : 'font-small-3 '
    }, [_vm._v(" (" + _vm._s(linkedReservationsData.isBookingGroup ? _vm.$t('reservation.groupTicket') : _vm.$t('reservation.splitIntineraryTicket')) + ") ")])], 1), _c('div', {
      staticClass: "text-airline d-flex flex-column align-items-center justify-content-end"
    }, [_c('div', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(linkedReservationsData.bookingCode) + " ")]), !_vm.isEmpty(linkedReservationsData.itineraries) ? _c('div', {
      staticClass: "font-medium-1 mt-50 text-nowrap text-black"
    }, [_c('span', [_vm._v(" " + _vm._s(linkedReservationsData.itineraries[0][0].departure.iataCode) + " ")]), !_vm.isMobileView ? _c('b-img', {
      staticClass: "mx-1",
      attrs: {
        "src": require("@icons/swap-horizontal.svg"),
        "width": "16"
      }
    }) : _c('span', [_vm._v(" - ")]), _c('span', [_vm._v(_vm._s(linkedReservationsData.itineraries[linkedReservationsData.itineraries.length - 1][linkedReservationsData.itineraries[linkedReservationsData.itineraries.length - 1].length - 1].arrival.iataCode))])], 1) : _c('div', {
      staticClass: "font-medium-1 mt-50 text-nowrap"
    }, [_vm.reservationsData ? _c('b-badge', {
      staticClass: "badge-glow round",
      attrs: {
        "variant": "light-".concat(_vm.resolveBookingStatusVariant(linkedReservationsData.status || linkedReservationsData.bookingStatus))
      }
    }, [_c('small', {
      class: "font-weight-bolder text-".concat(_vm.resolveBookingStatusVariant(linkedReservationsData.status || linkedReservationsData.bookingStatus))
    }, [_vm._v(" " + _vm._s(linkedReservationsData.status || linkedReservationsData.bookingStatus) + " ")])]) : _vm._e()], 1)])]);
  }), 1) : _vm._e(), !_vm.isEmpty((_vm$reservationsData8 = _vm.reservationsData) === null || _vm$reservationsData8 === void 0 ? void 0 : _vm$reservationsData8.crossReferenceDetails) ? _c('div', {
    staticClass: "mb-0 d-flex flex-row gap-1"
  }, _vm._l(_vm.reservationsData.crossReferenceDetails, function (booking, indexCrossReferenceDetails) {
    return _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      key: indexCrossReferenceDetails,
      staticClass: "p-25 p-md-50 d-flex flex-column align-items-center border-airline my-25",
      attrs: {
        "variant": "flat-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openCrossReferenceHandle(_vm.reservationsData, booking);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex-center w-100"
    }, [_c('IAmLogoAirline', {
      attrs: {
        "right": "",
        "airline": _vm.resolveAirlineImg(_vm.reservationsData),
        "size": "sm",
        "custom-class": "ml-50"
      }
    }), _c('div', {
      staticClass: "text-airline font-weight-bolder mr-50"
    }, [_vm._v(" " + _vm._s(booking.pnrNumber) + " ")])], 1), _c('i', {
      staticClass: "text-nowrap",
      class: _vm.isMobileView ? 'font-small-1' : 'font-small-3'
    }, [_vm._v(" (" + _vm._s(_vm.$t('reservation.splitPassengerTicket')) + ") ")])]);
  }), 1) : _vm._e()], 1)]), (_vm$reservationsData9 = _vm.reservationsData) !== null && _vm$reservationsData9 !== void 0 && (_vm$reservationsData10 = _vm$reservationsData9.supplierPNRs) !== null && _vm$reservationsData10 !== void 0 && _vm$reservationsData10.length ? _c('div', {
    staticClass: "mb-50 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "font-weight-bolder mr-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.airPnr')) + ": ")]), _vm._l((_vm$reservationsData11 = _vm.reservationsData) === null || _vm$reservationsData11 === void 0 ? void 0 : _vm$reservationsData11.supplierPNRs, function (supplierPNRItem, supplierPNRIndex) {
    return _c('div', {
      key: supplierPNRIndex,
      staticClass: "fw-700 text-20px text-primary"
    }, [_vm._v(" " + _vm._s(supplierPNRItem === null || supplierPNRItem === void 0 ? void 0 : supplierPNRItem.supplierPNR) + "*" + _vm._s(supplierPNRItem === null || supplierPNRItem === void 0 ? void 0 : supplierPNRItem.supplierCode) + " "), supplierPNRIndex !== _vm.reservationsData.supplierPNRs.length - 1 ? _c('span', [_vm._v(", ")]) : _vm._e()]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "mb-50 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "font-weight-bolder mr-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.status')) + ": ")]), _c('b-badge', {
    staticClass: "badge-glow rounded-lg px-75 py-50 mr-50",
    attrs: {
      "variant": "light-".concat(_vm.resolveBookingStatusVariant(_vm.reservationsData.status === 'PARTIALLY_PAID' ? 'PAID' : _vm.reservationsData.status || _vm.reservationsData.bookingStatus))
    }
  }, [_c('b', {
    class: "".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-3', " fw-800 text-uppercase text-").concat(_vm.resolveBookingStatusVariant(_vm.reservationsData.status === 'PARTIALLY_PAID' ? 'PAID' : _vm.reservationsData.status || _vm.reservationsData.bookingStatus))
  }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(_vm.reservationsData.status === 'PARTIALLY_PAID' ? 'PAID' : _vm.reservationsData.status || _vm.reservationsData.bookingStatus))) + " ")])]), _vm.reservationsData.status === 'PARTIALLY_PAID' ? _c('b-badge', {
    staticClass: "badge-glow rounded-lg px-75 py-50 mr-50",
    attrs: {
      "variant": "light-".concat(_vm.resolveBookingStatusVariant(_vm.reservationsData.status || _vm.reservationsData.bookingStatus))
    }
  }, [_c('b', {
    class: "".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-3', " fw-800 text-uppercase text-").concat(_vm.resolveBookingStatusVariant(_vm.reservationsData.status === 'PARTIALLY_PAID' ? 'PARTIALLY_PAID_ONLY' : _vm.reservationsData.status || _vm.reservationsData.bookingStatus))
  }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(_vm.reservationsData.status === 'PARTIALLY_PAID' ? 'PARTIALLY_PAID_ONLY' : _vm.reservationsData.status || _vm.reservationsData.bookingStatus))) + " ")])]) : _vm._e(), ['HOLD'].includes(_vm.reservationsData.status) && _vm.reservationsData.timeHold && !_vm.hideFeature ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary.window",
      modifiers: {
        "hover": true,
        "v-primary": true,
        "window": true
      }
    }],
    staticClass: "btn-icon rounded-circle",
    attrs: {
      "title": _vm.reservationsData.isPaymentReminder ? 'Đang BẬT thông báo nhắc xuất vé' : 'Đang TẮT thông báo nhắc xuất vé',
      "variant": "flat-".concat(_vm.reservationsData.isPaymentReminder ? 'success' : 'danger')
    },
    on: {
      "click": function click($event) {
        return _vm.handleTogglePaymentReminder(_vm.reservationsData);
      }
    }
  }, [_vm.reservationsData.isPaymentReminder ? _c('feather-icon', {
    attrs: {
      "icon": "BellIcon"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "BellOffIcon"
    }
  })], 1) : _vm._e()], 1), _vm.reservationsData.source === 'VJ' && _vm.reservationsData.refundAmountIdentification ? _c('div', {
    staticClass: "mb-50 font-weight-bolder"
  }, [_c('span', {
    staticClass: "text-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.amountRefunded')) + ": ")]), _c('span', {
    staticClass: "fw-700 font-medium-3 text-warning"
  }, [_vm._v(" -" + _vm._s(_vm.formatCurrency(_vm.reservationsData.refundAmountIdentification)) + " ")])]) : _vm._e(), _vm.reservationsData.timeHold && !_vm.reservationsData.status.includes('PAID') ? _c('div', {
    staticClass: "mb-50 font-weight-bolder"
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.$t('reservation.timeHold')) + ": ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.reservationsData.timeHold).dateTime) + " ")])]) : _vm._e(), _vm.lastDateToPurchase && !_vm.reservationsData.status.includes('PAID') ? _c('div', {
    staticClass: "mb-50 font-weight-bolder"
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.$t('reservation.priceDetails.titleTable.lastDateToPurchase')) + ": ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.lastDateToPurchase) + " ")])]) : _vm._e(), _vm.expirationTime && !_vm.reservationsData.status.includes('PAID') ? _c('div', {
    staticClass: "mb-50 font-weight-bolder"
  }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.$t('reservation.priceDetails.titleTable.expirationTime')) + ": ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.expirationTime) + " ")])]) : _vm._e(), _vm.reservationsData.autoIssueTime && _vm.reservationsData.status === 'HOLD' ? _c('div', {
    staticClass: "mb-50"
  }, [_c('span', {
    staticClass: "font-weight-bolder text-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.autoIssueTime')) + ": ")]), _c('span', {
    staticClass: "font-weight-bolder text-warning"
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.reservationsData.autoIssueTime).dateTime) + " ")])]) : _vm._e(), _vm.reservationsData.dateCreate ? _c('div', {
    staticClass: "mb-50"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.createdAt')) + ": ")]), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.reservationsData.dateCreate).dateTime) + " ")])]) : _vm._e(), _vm.reservationsData.totalAmountToBePaid && _vm.reservationsData.status !== 'CANCEL' ? _c('div', {
    staticClass: "sticky-total-price"
  }, [_c('IAmCollapseAlert', {
    attrs: {
      "show": _vm.isShowTotalPricePaid
    }
  }, [_c('div', {
    staticClass: "d-flex-center text-nowrap"
  }, [_c('span', {
    staticClass: "font-weight-bolder text-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.needPayment')) + ": ")]), _c('span', {
    staticClass: "font-medium-5 fw-700 ml-50",
    staticStyle: {
      "color": "#FF0500"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.reservationsData.totalAmountToBePaid) || 0) + " ")])])])], 1) : _vm._e(), _vm.reservationsData.header && _vm.reservationsData.header.includes('PNR MODIFIED - CHECK ITINERARY') ? _c('b-alert', {
    staticClass: "mb-50 px-1 py-50 d-flex-center ",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "AlertOctagonIcon",
      "size": "20"
    }
  }), _c('div', [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" PNR MODIFIED - CHECK ITINERARY ")]), _c('div', [_vm._v("Booking đã bị thay đổi. Vui lòng kiểm tra lại hành trình.")])])], 1) : _vm._e()], 1), _vm.reservationsData.contact && _vm.reservationsData.contact.paxInfo && _vm.isShowContactInfo ? _c('div', {
    staticClass: "d-flex flex-column gap-2"
  }, [_c('div', {
    staticClass: "fw-800 d-flex align-items-center justify-content-between w-100"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.contactInfo')) + " ")]), !['VN1A_MT', 'VN1A'].includes(_vm.reservationsData.source) && !_vm.isHideFunctionButton && !_vm.hideFeature ? _c('b-button', {
    staticClass: "p-50 ml-1 ml-md-0",
    attrs: {
      "variant": "flat-primary",
      "disabled": _vm.isBookingOutSystem || !_vm.canIssueLimit
    },
    on: {
      "click": _vm.openEditContactModalHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.detail')) + " ")]) : _vm._e()], 1), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('reservation.phoneNumber')) + ": ")]), _vm.reservationsData.contact.paxInfo.phone.length ? _c('span', {
    staticClass: "font-weight-bolder"
  }, _vm._l(_vm.reservationsData.contact.paxInfo.phone, function (phone, phoneIndex) {
    return _c('span', {
      key: phoneIndex
    }, [_c(phoneIndex === 0 || _vm.isMobileView ? 'span' : 'div', {
      tag: "component"
    }, [phone.prefix ? _c('span', [_vm._v(" (" + _vm._s(phone.prefix) + ") ")]) : _vm._e(), _vm._v(" " + _vm._s(phone.address ? phone.address : '-') + _vm._s(phoneIndex !== _vm.reservationsData.contact.paxInfo.phone.length - 1 ? ', ' : '') + " ")])], 1);
  }), 0) : _vm._e()]), _c('div', [_c('span', [_vm._v("Email:")]), !_vm.isEmpty(_vm.reservationsData.contact.paxInfo.email) ? _c('span', {
    staticClass: "font-weight-bolder"
  }, _vm._l(_vm.reservationsData.contact.paxInfo.email, function (email, emailIndex) {
    return _c('span', {
      key: emailIndex
    }, [_c(emailIndex === 0 || _vm.isMobileView ? 'span' : 'div', {
      tag: "component"
    }, [_vm._v(" " + _vm._s(email.address ? email.address : '-') + _vm._s(emailIndex !== _vm.reservationsData.contact.paxInfo.email.length - 1 ? ', ' : '') + " ")])], 1);
  }), 0) : _vm._e()])]) : _vm._e()])]), _c('div', {
    staticClass: "mt-0 mb-50 d-flex-center gap-1 justify-content-end"
  }, [_vm.reservationsData.source.includes('VN1A') && !_vm.isHideFunctionButton && !_vm.hideFeature ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": _vm.isBookingOutSystem
    },
    on: {
      "click": _vm.openSendQueuesModalHandle
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SendIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('reservation.sendQueues.title')) + " ")], 1) : _vm._e(), (['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) || ['1A'].includes(_vm.reservationsData.source) && !_vm.NDC_1A_BOOKING) && !_vm.isHideFunctionButton && !_vm.hideFeature ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": _vm.isBookingOutSystem
    },
    on: {
      "click": _vm.openRemarksModalHandle
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "TagIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('reservation.remarks.title')) + " ")], 1) : _vm._e(), _vm.reservationsData.source.includes('VN1A') && !_vm.isHideFunctionButton && !_vm.hideFeature ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": _vm.isBookingOutSystem
    },
    on: {
      "click": _vm.openOSIFlightModalHandle
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "BellIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('reservation.osi.title')) + " ")], 1) : _vm._e()], 1), _c('div', [_c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('b-img', {
          staticClass: "icon-card",
          attrs: {
            "src": require("@icons/info-outline.svg"),
            "width": "50px"
          }
        }), _c('h6', {
          staticClass: "text-airline font-medium-3 m-0 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.flightsDetails.title')) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('CancelFlight'), !_vm.reservationsData.itineraries.length && _vm.isEmpty(_vm.cancelFlightList) ? _c('div', [_c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "variant": "warning",
      "show": true
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.flightHasBeenCancelled')) + " ")])], 1) : _vm.reservationsData.itineraries.length ? _c('div', _vm._l(['1S', '1S_CTRL', '1G'].includes(_vm.reservationsData.source) && _vm.reservationsData.arunks && _vm.reservationsData.arunks.length ? _vm.addArunksToItineraries(_vm.reservationsData) : _vm.reservationsData.itineraries, function (flight, index) {
    return _c('div', {
      key: index
    }, [flight.segmentId ? _c('b-alert', {
      staticClass: "p-1",
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('reservation.haveArunk')) + " ")]) : _c('b-table-lite', {
      staticClass: "mb-50 border-secondary rounded-8px",
      attrs: {
        "bordered": "",
        "responsive": "",
        "items": flight,
        "fields": ['Flight'],
        "thead-class": "text-dark font-weight-bolder"
      },
      scopedSlots: _vm._u([_vm._l(['Flight'], function (column) {
        return {
          key: "head(".concat(column, ")"),
          fn: function fn(data) {
            return [_c('span', {
              key: column.label,
              staticClass: "text-dark text-nowrap"
            }, [_vm.indexFlightWithArunks ? _c('span', [_vm._v(_vm._s("".concat(_vm.$t("reservation.".concat(data.label)), " #").concat(_vm.indexFlightWithArunks[index] || index + 1)))]) : _c('span', [_vm._v(_vm._s("".concat(_vm.$t("reservation.".concat(data.label)), " #").concat(index + 1)))])])];
          }
        };
      }), {
        key: "cell(Flight)",
        fn: function fn(data) {
          var _data$item$stopAp, _vm$reservationsData12, _vm$reservationsData13;
          return [_c('b-row', [_c('b-col', {
            class: _vm.isMobileView ? 'mb-1' : 'pl-50 pr-0',
            attrs: {
              "cols": "12",
              "lg": "3"
            }
          }, [_c('div', {
            staticClass: "d-flex-center h-100 gap-2"
          }, [_c('IAmLogoAirline', {
            attrs: {
              "right": "",
              "airline": data.item.operating && data.item.operating.length === 2 && data.item.operating === 'BL' ? data.item.operating : data.item.airline,
              "size": "md"
            }
          }), _c('div', [_c('h5', {
            staticClass: "fw-700 font-medium-3"
          }, [_vm._v(" " + _vm._s(_vm.resolveAirlineFlightNumber(data.item.airline, data.item.flightNumber)) + " ")]), _c('h5', {
            staticClass: "font-weight-bolder align-middle mt-50 mb-0 font-medium-2"
          }, [_vm._v(" " + _vm._s(_vm.getAirCraftByIata(data.item.airCraft)) + " ")])])], 1)]), _c('b-col', {
            class: _vm.isMobileView ? 'px-50 mb-1' : 'px-0',
            attrs: {
              "cols": "12",
              "lg": "5"
            }
          }, [data.item.flightLegInfoList && data.item.flightLegInfoList.length > 0 ? _c('div', {
            staticClass: "d-flex-center flex-column h-100"
          }, _vm._l(data.item.flightLegInfoList, function (seg, segIndex) {
            return _c('div', {
              key: segIndex,
              staticClass: "d-flex-center py-50"
            }, [_c('div', {
              staticClass: "d-flex-center flex-column"
            }, [_c('b', {
              staticClass: "text-warning fw-700 font-medium-3"
            }, [_vm._v(" " + _vm._s(seg.departure.IATACode) + " ")]), _c('span', {
              directives: [{
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.v-primary",
                modifiers: {
                  "hover": true,
                  "v-primary": true
                }
              }],
              staticClass: "fw-700 font-medium-3",
              attrs: {
                "title": "GMT ".concat(seg.departure.timezone || seg.departure.timeZone)
              }
            }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(seg.departure.at, seg.departure.timezone || seg.departure.timeZone).time) + " ")]), _c('p', {
              staticClass: "mb-0"
            }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(seg.departure.at, seg.departure.timezone || seg.departure.timeZone).date) + " ")])]), _c('div', {
              staticClass: "d-flex flex-column align-items-center mx-1 my-auto"
            }, [_c('em', {
              staticClass: "mb-0"
            }, [_vm._v(" " + _vm._s(_vm.toHoursAndMinutes(seg.duration)) + " ")]), _c('div', {
              staticClass: "text-nowrap"
            }, [_c('b-img', {
              staticStyle: {
                "max-width": "180px"
              },
              attrs: {
                "src": require("@icons/line-flight.svg")
              }
            })], 1)]), _c('div', {
              staticClass: "d-flex flex-column align-items-center"
            }, [_c('div', [_c('b', {
              staticClass: "text-warning fw-700 font-medium-"
            }, [_vm._v(" " + _vm._s(seg.arrival.IATACode) + " ")])]), _c('span', {
              directives: [{
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.v-primary",
                modifiers: {
                  "hover": true,
                  "v-primary": true
                }
              }],
              staticClass: "fw-700 font-medium-3",
              attrs: {
                "title": "GMT ".concat(seg.arrival.timezone || seg.arrival.timeZone)
              }
            }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(seg.arrival.at, seg.arrival.timezone || seg.arrival.timeZone).time) + " ")]), _c('p', {
              staticClass: "mb-0"
            }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(seg.arrival.at, seg.arrival.timezone || seg.arrival.timeZone).date) + " ")])])]);
          }), 0) : _c('div', {
            staticClass: "d-flex-center flex-column gap-1 h-100",
            class: _vm.isMobileView ? 'mb-75' : ''
          }, [_c('div', {
            staticClass: "d-flex-center flex-1"
          }, [_c('div', {
            staticClass: "d-flex flex-column align-items-center"
          }, [_c('b', {
            staticClass: "text-warning fw-700 font-medium-3"
          }, [_vm._v(" " + _vm._s(data.item.departure.iataCode) + " ")]), _c('span', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.v-primary",
              modifiers: {
                "hover": true,
                "v-primary": true
              }
            }],
            staticClass: "fw-700 font-medium-3",
            attrs: {
              "title": "GMT ".concat(data.item.departure.timeZone || data.item.departure.timezone)
            }
          }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.departure.at, data.item.departure.timeZone || data.item.departure.timezone).time) + " ")]), _c('p', {
            staticClass: "mb-0"
          }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.departure.at, data.item.departure.timeZone || data.item.departure.timezone).date) + " ")])]), _c('div', {
            staticClass: "d-flex flex-column align-items-center mx-1 my-auto"
          }, [_c('em', {
            staticClass: "mb-0"
          }, [_vm._v(" " + _vm._s(_vm.toHoursAndMinutes(data.item.duration)) + " ")]), _c('div', {
            staticClass: "text-nowrap"
          }, [_c('b-img', {
            staticStyle: {
              "max-width": "180px"
            },
            attrs: {
              "src": require("@icons/line-flight.svg")
            }
          })], 1)]), _c('div', {
            staticClass: "d-flex flex-column align-items-center"
          }, [_c('b', {
            staticClass: "text-warning fw-700 font-medium-3"
          }, [_vm._v(" " + _vm._s(data.item.arrival.iataCode) + " ")]), _c('span', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover.v-primary",
              modifiers: {
                "hover": true,
                "v-primary": true
              }
            }],
            staticClass: "fw-700 font-medium-3",
            attrs: {
              "title": "GMT ".concat(data.item.arrival.timeZone || data.item.arrival.timezone)
            }
          }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.arrival.at, data.item.arrival.timeZone || data.item.arrival.timezone).time) + " ")]), _c('p', {
            staticClass: "mb-0"
          }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(data.item.arrival.at, data.item.arrival.timeZone || data.item.arrival.timezone).date) + " ")])])]), (_data$item$stopAp = data.item.stopAp) !== null && _data$item$stopAp !== void 0 && _data$item$stopAp.length ? _c('div', {
            staticClass: "d-flex-center gap-1"
          }, [_vm._v(" " + _vm._s(_vm.$t('reservation.stopAp')) + ": "), _vm._l(data.item.stopAp, function (stop, stopIndex) {
            return _c('span', {
              key: stop,
              staticClass: "fw-600"
            }, [_vm._v(" " + _vm._s(stop) + _vm._s(stopIndex !== data.item.stopAp.length - 1 ? ', ' : '') + " ")]);
          })], 2) : _vm._e()])]), _c('b-col', {
            staticClass: "px-1 d-flex",
            attrs: {
              "cols": "12",
              "lg": "4"
            }
          }, [_c('FlightInfoCard', {
            attrs: {
              "flight-info": data.item,
              "reservations-data": _vm.reservationsData,
              "index": data.index
            }
          })], 1), _vm.reservationsData.source === 'VU' && (_vm$reservationsData12 = _vm.reservationsData) !== null && _vm$reservationsData12 !== void 0 && (_vm$reservationsData13 = _vm$reservationsData12.paxLists.filter(function (p) {
            var _p$eticket;
            return p === null || p === void 0 ? void 0 : (_p$eticket = p.eticket) === null || _p$eticket === void 0 ? void 0 : _p$eticket.some(function (et) {
              var _data$item;
              return et === null || et === void 0 ? void 0 : et.segmentId.includes((_data$item = data.item) === null || _data$item === void 0 ? void 0 : _data$item.segmentId);
            });
          })) !== null && _vm$reservationsData13 !== void 0 && _vm$reservationsData13.length ? _c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('div', {
            staticClass: "fw-700 text-warning"
          }, [_vm._v(" " + _vm._s(_vm.$t('reservation.passenger')) + ": ")]), _c('div', _vm._l(_vm.reservationsData.paxLists.filter(function (p) {
            return p.eticket.some(function (et) {
              return et.segmentId.includes(data.item.segmentId);
            });
          }), function (pax, paxIndex) {
            return _c('div', {
              key: paxIndex
            }, [_c('div', {
              staticClass: "d-flex flex-column align-items-start mr-50"
            }, [_c('div', {
              class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-3'
            }, [_c('span', [_vm._v(_vm._s("".concat(paxIndex + 1, ". ")))]), _c('code', {
              staticClass: "text-body font-italic bg-transparent pl-0 pr-25"
            }, [_vm._v(" " + _vm._s("".concat(pax.paxType ? "(".concat(_vm.$t("reservation.".concat(pax.paxType)), ")") : '')) + " ")]), _c('b', [_vm._v(_vm._s("".concat(pax.lastName, " / ").concat(pax.firstName, " ").concat(pax.title || '', " ")))])])])]);
          }), 0)]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1);
  }), 0) : _vm._e()], 1)], 1), !_vm.isHideFunctionButton && !_vm.hideFeature ? _c('div', {
    staticClass: "w-100 d-flex-center justify-content-end mt-0 mb-50 gap-1 flex-wrap"
  }, [['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) || ['1G'].includes(_vm.reservationsData.source) && _vm.reservationsData.extra.some(function (item) {
    return item.contentSource === 'GDS';
  }) ? _c('b-button', {
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": !_vm.reservationsData.itineraries.length || _vm.isBookingOutSystem || !_vm.canIssueLimit || ['1G', 'VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) && _vm.reservationsData.status.includes('PAID')
    },
    on: {
      "click": function click($event) {
        return _vm.openRecalculatePriceModalHandle(_vm.reservationsData.source);
      }
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/reload.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.recalculatePriceFlight')))])], 1) : _vm._e(), ['VJ', 'VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('b-button', {
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": !_vm.canAccess('booking.addFlight') || _vm.isBookingOutSystem || !_vm.canIssueLimit
    },
    on: {
      "click": _vm.openAddFlightModalHandle
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/increase.svg"),
      "width": "16"
    }
  }), ['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.addFlight/DS')) + " ")]) : _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.addFlight')) + " ")])], 1) : _vm._e(), ['QH', 'VN1A'].includes(_vm.reservationsData.source) ? _c('b-button', {
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": _vm.isBookingOutSystem || !_vm.canIssueLimit
    },
    on: {
      "click": function click($event) {
        return _vm.handleConfirmSC(_vm.reservationsData.itineraries);
      }
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/checked.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirmSC')) + " ")])], 1) : _vm._e(), _vm.reservationsData.source.includes('1S') ? _c('b-button', {
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": _vm.isBookingOutSystem || !_vm.canIssueLimit
    },
    on: {
      "click": _vm.openSegmentsHandleModal
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/checked.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirmSegments.title')))])], 1) : _vm._e(), ['VJ', 'QH'].includes(_vm.reservationsData.source) ? _c('b-button', {
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "warning",
      "size": "sm",
      "disabled": _vm.disableRefundFlight(_vm.reservationsData) || !_vm.canAccess('booking.cancelFlight') || _vm.isBookingOutSystem || !_vm.canIssueLimit
    },
    on: {
      "click": function click($event) {
        return _vm.openCancelFlightModalHandle(_vm.reservationsData, 'REFUND');
      }
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/clear.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.reservationsData.source === 'VJ' ? _vm.$t('reservation.refundTicketVJ') : _vm.$t('reservation.refundTicket')))])], 1) : _vm._e(), ['VN1A', 'VN1A_MT', 'VJ', 'QH'].includes(_vm.reservationsData.source) ? _c('b-button', {
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "disabled": _vm.disableChangeFlight(_vm.reservationsData) || !_vm.canAccess('booking.changeFlight') || _vm.isBookingOutSystem || !_vm.canIssueLimit
    },
    on: {
      "click": function click($event) {
        return _vm.openChangeFlightHandle(_vm.reservationsData);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "RefreshCcwIcon"
    }
  }), ['VN1A', 'VN1A_MT', '1A'].includes(_vm.reservationsData.source) ? _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.changeItinerary')) + " ")]) : _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.changeFlights')) + " ")])], 1) : _vm._e(), !['VU', 'AK', 'TH', 'F1', '1A'].includes(_vm.reservationsData.source) || ['1A'].includes(_vm.reservationsData.source) && !_vm.NDC_1A_BOOKING ? _c('b-button', {
    staticClass: "px-75 d-flex-center gap-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "disabled": _vm.disableCancelFlight(_vm.reservationsData) || !_vm.canAccess('booking.cancelFlight') || _vm.isBookingOutSystem
    },
    on: {
      "click": function click($event) {
        return _vm.openCancelFlightModalHandle(_vm.reservationsData);
      }
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/clear.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.cancelFlight')))])], 1) : _vm._e()], 1) : _vm._e()], 1), _c('PassengerDetails', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "is-booking-out-system": _vm.isBookingOutSystem,
      "is-hide-function-button": _vm.isHideFunctionButton,
      "can-issue-limit": _vm.canIssueLimit
    }
  }), _c('PriceDetails', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "is-booking-out-system": _vm.isBookingOutSystem,
      "is-hide-function-button": _vm.isHideFunctionButton,
      "can-issue-limit": _vm.canIssueLimit,
      "is-locked-modify": _vm.isLockedModify
    }
  }), ['VN1A_MT', 'VN1A', '1S', '1S_CTRL', '1G', 'QH'].includes(_vm.reservationsData.source) ? _c('ETicketDetails', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "is-booking-out-system": _vm.isBookingOutSystem,
      "is-hide-function-button": _vm.isHideFunctionButton,
      "can-issue-limit": _vm.canIssueLimit,
      "is-locked-modify": _vm.isLockedModify
    }
  }) : _vm._e(), _c('OtherSpecialServices', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "is-booking-out-system": _vm.isBookingOutSystem,
      "is-hide-function-button": _vm.isHideFunctionButton,
      "can-issue-limit": _vm.canIssueLimit,
      "is-locked-modify": _vm.isLockedModify
    }
  }), ['VN1A_MT', 'VN1A', '1S', '1S_CTRL', '1G', '1A'].includes(_vm.reservationsData.source) ? _c('NotiFromSystem', {
    attrs: {
      "reservations-data": _vm.reservationsData,
      "is-booking-out-system": _vm.isBookingOutSystem,
      "is-hide-function-button": _vm.isHideFunctionButton,
      "can-issue-limit": _vm.canIssueLimit
    }
  }) : _vm._e(), _vm.reservationsData.auditLogs ? _c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": false,
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('b-img', {
          staticClass: "icon-card",
          attrs: {
            "src": require("@icons/history-actions.svg"),
            "width": "50px"
          }
        }), _c('h6', {
          staticClass: "text-airline font-medium-3 m-0 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.historyActions.title.general')) + " ")])], 1)];
      },
      proxy: true
    }], null, false, 724281431)
  }, [_c('b-tabs', {
    staticClass: "mt-50",
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.$t('reservation.historyActions.title.payment')))])];
      },
      proxy: true
    }], null, false, 3101372553)
  }, [_c('ActionsHistory', {
    attrs: {
      "history-data": _vm.reservationsData.auditLogs.filter(function (item) {
        return item.isPayment !== null ? item.isPayment : item.newValue;
      })
    }
  })], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.$t('reservation.historyActions.title.all')))])];
      },
      proxy: true
    }], null, false, 3468900498)
  }, [_c('ActionsHistory', {
    attrs: {
      "history-data": _vm.reservationsData.auditLogs,
      "source": _vm.reservationsData.source,
      "show-modal": true
    }
  })], 1), _vm.reservationsData.source === 'VJ' && _vm.reservationsData.charges && !_vm.isRoleF3 ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.$t('reservation.historyActions.title.charges')))])];
      },
      proxy: true
    }], null, false, 1282788410)
  }, [_c('ChargesFromSource', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  })], 1) : _vm._e(), _vm.reservationsData.source === 'VJ' && !_vm.isRoleF3 ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.$t('reservation.historyActions.title.fromSource')))])];
      },
      proxy: true
    }], null, false, 2165660856)
  }, [_c('ActionsHistoryFromSource', {
    attrs: {
      "source-data": _vm.reservationsData.source,
      "booking-code-data": _vm.reservationsData.bookingCode
    }
  })], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('CustomerAndBookerDetails', {
    attrs: {
      "reservations-data": _vm.reservationsData
    }
  }), _c('div', {
    staticClass: "payment-div d-flex-between py-1 px-0 px-lg-4"
  }, [!_vm.isMobileView ? _c('div') : _vm._e(), !_vm.hideFeature || _vm.reservationsData.isLoadBookingFail ? _c('div', {
    staticClass: "d-flex-center"
  }, [_vm.reservationsData && _vm.reservationsData.status === 'DRAFT' ? _c('div', {
    staticClass: "divider-after"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary",
      modifiers: {
        "hover": true,
        "v-primary": true
      }
    }],
    staticClass: "px-50 px-md-2 mx-50 mx-md-1",
    attrs: {
      "title": _vm.$t('reservation.submitBookingInfo'),
      "variant": "primary",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleSubmitDraft(_vm.reservationsData);
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap text-truncate"
  }, [_vm._v(_vm._s(_vm.$t('reservation.submitBooking')))])])], 1) : _vm._e(), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-1 px-md-2 mx-50 mx-md-1",
    attrs: {
      "id": "ticket-face-button",
      "variant": "primary",
      "pill": ""
    },
    on: {
      "click": _vm.handleTicketFaceButton
    }
  }, [_c('span', {
    staticClass: "text-nowrap text-truncate"
  }, [_vm._v(_vm._s(_vm.$t('reservation.exportTicket')))])]), _c('b-popover', {
    staticClass: "rounded p-25",
    attrs: {
      "show": _vm.showPopoverCombinationTicket,
      "target": "ticket-face-button",
      "triggers": "blur",
      "boundary": "window",
      "placement": "top"
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.showPopoverCombinationTicket = $event;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex-center flex-column gap-3 p-1"
  }, [_c('b-button', {
    staticClass: "px-2 py-1 border",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.goToTicketFace(true);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('feather-icon', {
    staticClass: "mr-50 rounded-circle p-25",
    staticStyle: {
      "transform": "rotate(45deg)",
      "width": "25px",
      "height": "25px",
      "border": "1px solid white"
    },
    attrs: {
      "size": "25",
      "icon": "Minimize2Icon"
    }
  }), _c('span', {
    staticClass: "text-16px font-weight-bolder"
  }, [_vm._v("Gộp mặt vé")])], 1)]), _c('b-button', {
    staticClass: "px-2 py-1 border",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.goToTicketFace(false);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [_c('feather-icon', {
    staticClass: "mr-50 rounded-circle p-25",
    staticStyle: {
      "transform": "rotate(45deg)",
      "width": "25px",
      "height": "25px",
      "border": "1px solid white"
    },
    attrs: {
      "size": "25",
      "icon": "Maximize2Icon"
    }
  }), _c('span', {
    staticClass: "text-16px font-weight-bolder"
  }, [_vm._v("Tách mặt vé")])], 1)])], 1)]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "px-1 px-md-2 mx-50 mx-md-1",
    attrs: {
      "variant": "primary",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('bv::show::modal', 'id-modal-reservation-sms');
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap text-truncate"
  }, [_vm._v(_vm._s(_vm.$t('reservation.exportSms')))])]), _vm.reservationsData && _vm.reservationsData.status === 'HOLD' && _vm.reservationsData.isDraft ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary",
      modifiers: {
        "hover": true,
        "v-primary": true
      }
    }],
    staticClass: "px-50 px-md-2 mx-50 mx-md-1",
    attrs: {
      "title": _vm.$t('reservation.sendSubmitBookingMail'),
      "variant": "primary",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleSendSubmitBookingMail(_vm.reservationsData);
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap text-truncate"
  }, [_vm._v(_vm._s(_vm.$t('reservation.sendSubmitBookingMail')))])]) : _vm._e()], 1) : _vm._e(), !_vm.hideFeature ? _c('div', [_vm.reservationsData && _vm.reservationsData.status !== 'DRAFT' ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary",
      modifiers: {
        "hover": true,
        "v-primary": true
      }
    }],
    staticClass: "px-50 px-md-2 mx-50 mx-md-1",
    attrs: {
      "title": _vm.$t('reservation.payment'),
      "variant": "danger",
      "pill": "",
      "disabled": _vm.isDisabledPaymentFlight(_vm.reservationsData) || !_vm.canAccess('booking.issueTicket') || _vm.isBookingOutSystem || !_vm.canIssueLimit || _vm.isLockedModify
    },
    on: {
      "click": function click($event) {
        return _vm.openPaymentFlightModalHandle(_vm.reservationsData);
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap text-truncate"
  }, [_vm._v(_vm._s(_vm.$t('reservation.payment')))])]) : _vm._e()], 1) : _c('div')]), _c('ModalRecalculatePrice', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  }), _c('ModalRecalculateVN1A', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  }), _c('ModalEditContact', {
    attrs: {
      "data-reservation": _vm.reservationsData
    }
  }), _c('ModalCancelFlight', {
    attrs: {
      "is-check-price": _vm.isCheckPrice,
      "action-cancel-trip": _vm.actionCancelTrip,
      "segment-id-to-disabled": _vm.segmentIdToDisabled
    }
  }), ['VN1A', 'VN1A_MT'].includes(_vm.reservationsData.source) ? _c('ModalCancelFlightVN1A', {
    attrs: {
      "source": _vm.reservationsData.source,
      "pnr-number": _vm.reservationsData.bookingCode,
      "pax-code": (_vm$reservationsData$ = _vm.reservationsData.paxContact) === null || _vm$reservationsData$ === void 0 ? void 0 : _vm$reservationsData$.code,
      "agency-code": _vm.reservationsData.agency
    }
  }) : _vm._e(), _vm.reservationsData ? _c('ModalChangeFlight') : _vm._e(), _vm.reservationsData ? _c('ModalPaymentFlight', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  }) : _vm._e(), _c('ModalRemarks', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  }), _c('ModalOSIFlight', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  }), _c('ModalSendQueues', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  }), _c('ModalSpecialServices', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  }), _c('ModalConfirmSegments', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  }), _c('ModalSubmitDraft', {
    attrs: {
      "booking-data": _vm.reservationsData
    },
    on: {
      "refetch-booking-data": _vm.refetchBookingData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }